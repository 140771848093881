
import { mapGetters } from 'vuex'

import ProductPartialsRating from '@/components/product/partials/Rating'

export default {
  components: {
    ProductPartialsRating
  },

  props: {
    product: {
      required: true,
      type: Object
    },
    schemaOrg: {
      required: false,
      default () {
        return false
      },
      type: Boolean
    },
    loading: {
      required: false,
      default () {
        return 'lazy'
      },
      type: String
    }
  },

  data: () => ({
    warranty: {
      types: []
    },
    addon: {
      types: []
    },
    conditionStatusLimit: 2,
    variations: {
      mobileSmall: {
        square: 4,
        inscription: 2
      }
    }
  }),

  computed: {
    ...mapGetters({
      cart: 'cart/cart',
      cartModal: 'cart/modal',
      country: 'country',
      currency: 'currency',
      settings: 'settings'
    }),

    itemTypeSchemaOrgProduct () {
      const data = {}

      if (this.schemaOrg) {
        data.itemtype = 'https://schema.org/Product'
        data.itemscope = true
      }

      return data
    },

    itemTypeSchemaOrgBrand () {
      const data = {}

      if (this.schemaOrg) {
        data.itemtype = 'https://schema.org/Brand'
        data.itemscope = true
        data.itemprop = 'brand'
      }

      return data
    },

    itemTypeSchemaOrgOffer () {
      const data = {}

      if (this.schemaOrg) {
        data.itemtype = 'https://schema.org/Offer'
        data.itemscope = true
        data.itemprop = 'offers'
      }

      return data
    },

    itemPropSchemaOrgImage () {
      const data = {}

      if (this.schemaOrg) {
        data.itemprop = 'image'
      }

      return data
    },

    itemPropSchemaOrgName () {
      const data = {}

      if (this.schemaOrg) {
        data.itemprop = 'name'
      }

      return data
    },

    conditionNew () {
      return ['new'].includes(this.product.store.conditionStatus.type)
    },

    available () {
      return ['available', 'awaiting', 'preorder', 'produce'].includes(this.product.store.stockStatus.type)
    },

    badges () {
      return this.product.badges.slice(0, 4)
    },

    stickers () {
      return this.product.stickers.slice(0, 4)
    },

    link () {
      return this.localePath({
        name: 'slug',
        params: {
          slug: this.product.page.slug
        }
      })
    },

    storesLink () {
      return this.localePath({
        name: 'slug',
        params: {
          slug: this.product.page.slug
        },
        query: {
          tab: 'stores'
        }
      })
    },

    reviewsLink () {
      return this.localePath({
        name: 'slug',
        params: {
          slug: this.product.page.slug
        },
        query: {
          tab: 'stores'
        }
      })
    },

    cover () {
      if (this.product.images.length > 0) {
        return {
          src: `${this.product.images[0].folder}/card/lazy/${this.product.images[0].url}`,
          placeholder: `${this.product.images[0].folder}/card/src/${this.product.images[0].url}`
        }
      } else {
        return {
          src: this.$config.noImage,
          placeholder: this.$config.noImage
        }
      }
    },

    productHasVariations () {
      return this.product.variations && this.product.variations.length > 0
    },

    displayProductPrice () {
      return !['unavailable', 'preorder', 'discontinued'].includes(this.product.store.stockStatus.type)
    },

    productStoreOrderableConditionStatuses () {
      return this.product.productStoreOrderableConditionStatuses.slice(0, this.conditionStatusLimit)
    },

    addOnPrice () {
      let addonsPrice = 0
      let warrantiesPrice = 0

      this.addon.types.forEach((type) => {
        addonsPrice += (Object.keys(type).length ? type.price : 0)
      })

      this.warranty.types.forEach((type) => {
        warrantiesPrice += (Object.keys(type).length ? type.price : 0)
      })

      return warrantiesPrice + addonsPrice
    },

    oldPrice () {
      if (this.product.store.price.old) {
        return this.product.store.price.old + this.addOnPrice
      }

      return 0
    },

    price () {
      return this.product.store.price.current + this.addOnPrice
    }
  },

  methods: {
    addToCart (item, itemStore, warranties, addons) {
      this.$store.dispatch('cart/setCartType', 'cart')
      this.$store.dispatch('cart/addToCart', { item, itemStore, warranties, addons })
      this.$store.dispatch('cart/setCartModalActive', !this.cartModal.active)
    },

    togglePreorderModal () {
      this.$store.dispatch('modal/setPreorderId', this.product.id)
      this.$store.dispatch('modal/setPreorderTitle', this.product.title)
      this.$store.dispatch('modal/setPreorderImage', {
        src: `${this.product.images[0].folder}/card/src/${this.product.images[0].url}`,
        lazy: `${this.product.images[0].folder}/card/lazy/${this.product.images[0].url}`,
        alt: this.product.images[0].alt
      })
      this.$store.dispatch('modal/togglePreorderModal', true)
    },

    toggleNotifyAboutAvailabilityModal () {
      this.$store.dispatch('modal/setNotifyAboutProductAvailabilityId', this.product.id)
      this.$store.dispatch('modal/setNotifyAboutProductAvailabilityTitle', this.product.title)
      this.$store.dispatch('modal/setNotifyAboutProductAvailabilityImage', {
        src: `${this.product.images[0].folder}/card/src/${this.product.images[0].url}`,
        lazy: `${this.product.images[0].folder}/card/lazy/${this.product.images[0].url}`,
        alt: this.product.images[0].alt
      })
      this.$store.dispatch('modal/toggleNotifyAboutProductAvailabilityModal', true)
    },

    isVariationSquare (characteristic) {
      return ['color', 'image', 'composite'].includes(characteristic.variation_type)
    },

    getVariation (id) {
      this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/product/${id}`)
        .then((res) => {
          this.product = res.data

          this.$nextTick(() => {
            this.$refs.images.querySelector('img').setAttribute('src', `${this.product.images[0].folder}/card/lazy/${this.product.images[0].url}`)
          })
        })
    }
  }
}
