import { render, staticRenderFns } from "./JournalArticle.vue?vue&type=template&id=5a6f6896"
import script from "./JournalArticle.vue?vue&type=script&lang=js"
export * from "./JournalArticle.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumbs: require('/app/components/Breadcrumbs.vue').default,JournalShare: require('/app/components/journal/Share.vue').default,IndexJournalArticle: require('/app/components/index/JournalArticle.vue').default})
