import { render, staticRenderFns } from "./CartModal.vue?vue&type=template&id=4847e2b2"
import script from "./CartModal.vue?vue&type=script&lang=js"
export * from "./CartModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CartFooter: require('/app/components/CartFooter.vue').default,Cartable: require('/app/components/Cartable.vue').default})
